import {ContentBox, ContentMode} from '@hconnect/common/components/ContentBox'
import {DashboardLink} from '@hconnect/common/components/downtime'
import {EquipmentPerformanceResult} from '@hconnect/common/components/kpiPerformance/EquipmentPerformanceResult'
import {OperatingHoursLegendList} from '@hconnect/common/components/runningTimes'
import {ExpandedGroupIds, useExpandedGroups} from '@hconnect/common/hooks/useExpandGroups'
import {
  EquipmentPerformance,
  EquipmentRunningTimes,
  RunningTime,
  ManualKpisProcessStage,
  ManualKpiToUpdate,
  ManualKpiResolution,
  RunningTimesEquipmentData
} from '@hconnect/common/types'
import {checkIfKpiPerformanceIsAvailable, getUnmatchedEquipments} from '@hconnect/common/utils'
import {useNotification} from '@hconnect/uikit'
import {ExpandCollapseButton} from '@hconnect/uikit/src/lib2'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import {Box, Button} from '@mui/material'
import {TFunction} from 'i18next'
import {isEmpty} from 'lodash'
import React, {useCallback, useMemo, useState} from 'react'
import {useForm, UseFormHandleSubmit} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {PerformanceManualKpiContainer} from '../../containers/shiftSummary/PerformanceManualKpiContainer'
import {useProcessStagesForManualKpis, useUpdateKpis} from '../../hooks/api/kpis'
import {useEquipmentPerformances} from '../../hooks/api/useEquipmentPerformances'
import {useConfig} from '../../hooks/useConfig'
import {useFilterDatePicker} from '../../hooks/useDatePicker'
import {useHasPermission} from '../../hooks/usePermission'
import {KpiFormData} from '../../types/kpi.types'
import {mapFormDataToKpisToUpdate} from '../../utils'

type Props = {
  contentMode: ContentMode
  isLoading?: boolean
  runningTimePerformances?: EquipmentRunningTimes[]
  getStackedBarChartComponent: (
    equipment: RunningTimesEquipmentData,
    runningTimes: RunningTime[]
  ) => React.ReactNode
  plantId: string
}

type ActionProps = {
  isManualKpisEnabled: boolean
  isEditMode: boolean
  isKpiPerformanceAvailable: boolean
  t: TFunction
  plantId: string
  handleSubmit: UseFormHandleSubmit<KpiFormData>
  onSubmit: (data: KpiFormData) => void
  setIsEditMode: (isEditMode: boolean) => void
  openProcessStages: string[]
  setOpenProcessStages: (id: ExpandedGroupIds) => void
  processStages: ManualKpisProcessStage[]
  equipmentIds: string[]
  expandedEquipments: string[]
  toggleExpandedEquipments: (id: ExpandedGroupIds) => void
  isManualKpiEditAllowed: boolean
}

const getActions = ({
  isManualKpisEnabled,
  isEditMode,
  isKpiPerformanceAvailable,
  t,
  plantId,
  handleSubmit,
  onSubmit,
  setIsEditMode,
  openProcessStages,
  setOpenProcessStages,
  processStages,
  equipmentIds,
  expandedEquipments,
  toggleExpandedEquipments,
  isManualKpiEditAllowed
}: ActionProps) => (
  <Box display="flex" alignItems="center" flexWrap="wrap">
    {isManualKpisEnabled &&
      (isEditMode ? (
        <>
          <Button
            onClick={() => setIsEditMode(false)}
            startIcon={<CloseOutlinedIcon />}
            variant="text"
            data-test-id="performance-edit-close-button"
          >
            {t('action.cancel')}
          </Button>
          <Button
            onClick={() => void handleSubmit(onSubmit)()}
            startIcon={<DoneOutlinedIcon />}
            variant="contained"
            data-test-id="performance-edit-save-button"
          >
            {t('action.save')}
          </Button>
        </>
      ) : (
        <>
          <ExpandCollapseButton
            variant="text"
            expanded={openProcessStages.length === processStages.length}
            onClick={() => setOpenProcessStages('all')}
            data-test-id="performance-expand-all-button"
            expandLabel={t('action.expand')}
            collapseLabel={t('action.collapse')}
          />
          {isManualKpiEditAllowed && (
            <Button
              onClick={() => setIsEditMode(true)}
              startIcon={<ModeEditOutlineOutlinedIcon />}
              variant="text"
              data-test-id="performance-edit-button"
            >
              {t('summary.action.edit')}
            </Button>
          )}
        </>
      ))}
    {!isManualKpisEnabled && isKpiPerformanceAvailable && (
      <ExpandCollapseButton
        variant="text"
        expanded={expandedEquipments && expandedEquipments.length === equipmentIds.length}
        onClick={() => toggleExpandedEquipments('all')}
        data-test-id="performance-expand-all-button"
        expandLabel={t('action.expand')}
        collapseLabel={t('action.collapse')}
      />
    )}
    {!isEditMode && <DashboardLink plantId={plantId} title={t('goToDashboard')} />}
  </Box>
)

export const PerformanceSummary: React.FC<Props> = ({
  contentMode,
  isLoading,
  runningTimePerformances,
  getStackedBarChartComponent,
  plantId
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const hasPermission = useHasPermission()
  const {notify} = useNotification()
  const {timeRange, isSingleShift, quickSelectSlot} = useFilterDatePicker()
  const {pxTrendAvailable, cceAvailable} = useConfig()
  const {data: equipmentPerformances} = useEquipmentPerformances(timeRange)
  const [isEditMode, setIsEditMode] = useState(false)

  const isManualKpiEditAllowed = hasPermission('CREATE_SHIFT_REPORT')

  const equipmentIds: string[] =
    equipmentPerformances?.map((equipmentPerformance) => equipmentPerformance.equipment.id) || []

  const [expandedEquipments, toggleExpandedEquipments] = useExpandedGroups(
    equipmentIds,
    'summary-expanded-equipments'
  )

  const isKpiPerformanceAvailable = checkIfKpiPerformanceIsAvailable(equipmentPerformances)

  const isTimeFrameSupported = isSingleShift || quickSelectSlot === 'yesterday'
  const isManualKpisEnabled = (!pxTrendAvailable || !cceAvailable) && isTimeFrameSupported
  const {data: processStages = [], isLoading: isProcessStagesLoading} =
    useProcessStagesForManualKpis(language, {enabled: isManualKpisEnabled})

  const [openProcessStages, setOpenProcessStages] = useExpandedGroups(
    processStages.map(({code}) => code),
    'expanded-manual-kpis'
  )

  const {control, handleSubmit} = useForm<KpiFormData>({shouldUnregister: true})

  const [manualKpiList, setManualKpiList] = useState<ManualKpiToUpdate[]>([])

  const {
    mutate: updateKpis,
    error: updateError,
    reset: updateReset
  } = useUpdateKpis(
    isSingleShift ? ManualKpiResolution.PerShift : ManualKpiResolution.Daily,
    timeRange.endDate,
    {
      onSuccess: () => {
        notify('success', t('performance.action.kpiSaveSuccess'))
        setIsEditMode(false)
      }
    }
  )

  const unmatchedEquipments = useMemo(
    () => getUnmatchedEquipments(equipmentPerformances, runningTimePerformances),
    [equipmentPerformances, runningTimePerformances]
  )

  const onSubmit = useCallback(
    (data: KpiFormData) => {
      const updatedKpis = mapFormDataToKpisToUpdate(data, manualKpiList)
      updateKpis(updatedKpis)
    },
    [manualKpiList, updateKpis]
  )

  return (
    <ContentBox
      title={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={1}
        >
          {t('performance.label.performance')}
          {getActions({
            isManualKpisEnabled,
            isEditMode,
            isKpiPerformanceAvailable,
            t,
            plantId,
            handleSubmit,
            onSubmit,
            setIsEditMode,
            openProcessStages,
            setOpenProcessStages,
            processStages,
            equipmentIds,
            expandedEquipments,
            toggleExpandedEquipments,
            isManualKpiEditAllowed
          })}
        </Box>
      }
      mode={contentMode}
      bodyWithPadding
      isLoading={isLoading}
      minHeight="450px"
      data-test-id={'equipment-performance-container'}
    >
      {!isEditMode && (!isEmpty(equipmentPerformances) || !isEmpty(unmatchedEquipments)) && (
        <OperatingHoursLegendList />
      )}
      {!isEditMode && !isEmpty(equipmentPerformances) && (
        <>
          {equipmentPerformances?.map(
            (equipmentPerformanceData: EquipmentPerformance, index: number) => {
              return (
                <EquipmentPerformanceResult
                  {...equipmentPerformanceData}
                  runningTimePerformance={runningTimePerformances?.find(
                    ({equipment}) =>
                      equipment.matchingId === equipmentPerformanceData.equipment.matchingId
                  )}
                  key={equipmentPerformanceData.equipment.id}
                  isExpanded={expandedEquipments.includes(equipmentPerformanceData.equipment.id)}
                  getStackedBarChartComponent={getStackedBarChartComponent}
                  isLast={
                    isEmpty(unmatchedEquipments) && equipmentPerformances?.length === index + 1
                  }
                  toggleExpandedEquipments={toggleExpandedEquipments}
                />
              )
            }
          )}
        </>
      )}
      {!isEditMode &&
        unmatchedEquipments?.map(
          (equipmentPerformanceData: EquipmentRunningTimes, index: number) => {
            return (
              <EquipmentPerformanceResult
                runningTimePerformance={equipmentPerformanceData}
                equipment={equipmentPerformanceData.equipment}
                key={equipmentPerformanceData.equipment.id}
                getStackedBarChartComponent={getStackedBarChartComponent}
                isLast={unmatchedEquipments?.length === index + 1}
                expandButtonGap={!isEmpty(equipmentPerformances)}
              />
            )
          }
        )}
      {isManualKpisEnabled && (
        <PerformanceManualKpiContainer
          isEditMode={isEditMode}
          isEnabled={isTimeFrameSupported}
          isProcessStagesLoading={isProcessStagesLoading}
          openProcessStages={openProcessStages}
          setOpenProcessStages={setOpenProcessStages}
          processStages={processStages}
          manualKpiList={manualKpiList}
          setManualKpiList={setManualKpiList}
          control={control}
          updateError={updateError}
          updateReset={updateReset}
        />
      )}
    </ContentBox>
  )
}
